<template>
  <main>
    <v-card class="overflow-hidden">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" lg="6">
            <span class="green--text">Nombre</span>
            <v-text-field
              v-model="user.first_name"
              :error-messages="validationErrors.first_name"
              solo
              dense
              placeholder="Escriba el nombre"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="6">
            <span class="green--text">Apellido</span>
            <v-text-field
              v-model="user.last_name"
              :error-messages="validationErrors.last_name"
              solo
              dense
              placeholder="Escriba el nombre"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="6">
            <span class="green--text">Documento de identidad</span>
            <v-text-field
              v-model="user.username"
              :error-messages="validationErrors.username"
              solo
              dense
              placeholder="Escriba el nombre"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="6">
            <span class="green--text">Email</span>
            <v-text-field
              v-model="user.email"
              :error-messages="validationErrors.email"
              solo
              dense
              placeholder="Escriba el nombre"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" lg="6" md="6">
            <span class="green--text">Role</span>
            <v-autocomplete
              dense
              solo
              placeholder="Seleccione un valor"
              :items="rolesItems"
              :error-messages="validationErrors.role_id"
              item-text="name"
              item-value="id"
              v-model="user.role_id"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="6">
            <span class="green--text">Contraseña</span>
            <v-text-field
              v-model="user.password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :error-messages=" mode != 'edit' ? validationErrors.password : ''"
              placeholder="Escriba la contraseña"
              solo
              dense
              @click:append="showPassword = !showPassword"
              :type="showPassword ? 'text' : 'password'"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="6">
            <span class="green--text">Tipo usuario</span>
            <v-autocomplete
              dense
              solo
              placeholder="Seleccione un valor"
              @input="showOptions()"
              :items="itemsTypeUser"
              :error-messages="validationErrors.type_user"
              item-text="name"
              item-value="id"
              v-model="user.type_user"
            >
            </v-autocomplete>
          </v-col>
          <v-col v-show="showDepto" cols="12" md="6" lg="4">
            <span class="green--text">Departamento</span>
            <v-autocomplete
              :items="departments"
              v-model="user.department_id"
              placeholder="Seleccione un valor"
              item-text="descripcion"
              item-value="departamento_id"
              solo
              dense
            ></v-autocomplete>
          </v-col>
          <v-col v-show="showZone" cols="12" md="6" lg="4">
            <span class="green--text">Zona</span>
            <v-autocomplete
              :items="zone"
              v-model="user.zone_id"
              item-text="descripcion"
              item-value="zona_id"
              solo
              dense
              placeholder="Seleccione un valor"
            ></v-autocomplete>
          </v-col>
          <v-col v-show="showMuni" cols="12" md="6" lg="4">
            <span class="green--text">Municipio</span>
            <v-autocomplete
              v-model="user.municipality_id"
              solo
              dense
              :items="municipalitiesFilter"
              placeholder="Seleccione un valor"
              item-text="descripcion"
              item-value="municipio_id"
            >
            </v-autocomplete>
          </v-col>
          <v-col v-show="showTerri" cols="12" md="6" lg="6">
            <span class="green--text">Territorio</span>
            <v-autocomplete
              dense
              solo
              placeholder="Seleccione un valor"
              :items="itemsTerritory"
              item-text="descripcion"
              item-value="territorio_id"
              v-model="user.territory_id"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="text-center mt-2 mb-2">
      <v-btn small class="mx-2" color="primary" rounded @click="save()">
        Guardar
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </div>
  </main>
</template>

<script>
import { updateUsers, storeUsers, listRoles } from "@/services/configService";
import sweetalert from "sweetalert2";
import { required, numeric } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import getEventStateColor from "../../../utils/eventStateColors";

export default {
  props: {
    mode: {
      type: String,
      default: "create",
    },
  },
  name: "newUser.vue",
  data() {
    return {
      menu2: false,
      timeMenu: false,
      municipalities: [],
      territories: [],
      showDepto: false,
      showZone: false,
      showMuni: false,
      showTerri: false,
      departments: [],
      zone: [],
      itemsTypeUser: [
        {
          id: "regional",
          name: "Regional",
        },
        {
          id: "zonal",
          name: "Zonal",
        },
        {
          id: "local",
          name: "Local",
        },
      ],
      search: "",
      territoryTypes: [],
      disharmonyTypes: [],
      itemsTerritory: [],
      rolesItems: [],
      showPassword: false,
      show1: false,
      user: {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        territory_id: null,
        password: "",
        role_id: "",
        id: "",
        type_user: "",
        department_id: null,
        zone_id: null,
        municipality_id: null,
      },
      eventId: "",
    };
  },
  validations() {
    return {
      user: {
        type_user: { required },
        first_name: { required },
        last_name: { required },
        username: { required, numeric },
        email: { required },
        password: this.mode != "edit" ? { required } : {},
        role_id: { required },
      },
    };
  },
  methods: {
    async getCounter() {
      this.itemsTerritory = await this.dataSource.data.Territorio;
    },
    addAffected(affected) {
      this.user.affecteds.push(affected);
    },
    removeAffected(affected) {
      console.log(affected);
      const index = this.user.affecteds.indexOf(affected);
      if (index !== -1 && affected["id"] !== undefined)
        this.user.affecteds[index]["remove"] = true;
      if (index !== -1 && affected["id"] === undefined)
        this.user.affecteds.splice(index, 1);
    },
    showOptions() {
      this.showDepto = false;
      this.showZone = false;
      this.showMuni = false;
      this.showTerri = false;
      if (this.user.type_user == "regional") this.showDepto = true;

      if (this.user.type_user == "local") {
        this.showTerri = true;
      }

      if (this.user.type_user == "zonal") {
        this.showDepto = true;
        this.showZone = true;
      }
    },
    async save() {
      this.$v.user.$touch();
      if (this.$v.user.$invalid) return;
      try {
        this.showLoader();

        console.log('password', this.user.password)

        const eventRequestData = {
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          username: this.user.username,
          email: this.user.email,
          territory_id: this.user.territory_id,
          password: this.user.password ? this.user.password : null,
          role_id: this.user.role_id,
          type_user: this.user.type_user,
          department_id: this.user.department_id,
          municipality_id: 1,//this.user.municipality_id,
          zone_id: this.user.zone_id,
        };

        const storeEventResponse =
          this.mode === "edit"
            ? await updateUsers(this.user.id, eventRequestData)
            : await storeUsers(eventRequestData);

        this.eventId = storeEventResponse.data.event_id;

        await sweetalert.fire("Registro guardado correctamente", "", "success");

        this.hideLoader();

        this.$router.push({
          name: "generalConfig",
        });
      } catch (err) {
        this.hideLoader();
        console.error(err);
        sweetalert.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
    setEventData(eventData) {
      this.user = eventData;
      this.showOptions();
    },
    eventStateColor(state) {
      return getEventStateColor(state) || "gray";
    },
  },
  async created() {
    this.showLoader();
    this.municipalities = this.dataSource.data.Municipio;
    this.departments = this.dataSource.data.Departamento;
    this.itemsTerritory = await this.dataSource.data.Territorio;
    this.zone = this.dataSource.data.Zona;
    let roles = await listRoles();
    this.rolesItems = roles.data;
    this.hideLoader();
  },
  computed: {
    municipalitiesFilter() {
      let municipalities =  this.municipalities.filter(
        (x) => x.departamento === this.user.department_id
      );

      if (this.user.zone_id != null) {
        municipalities = this.municipalities.filter(
          (x) => x.zona === this.user.zone_id
        );
      }

      return municipalities;
    },
    validationErrors() {
      return {
        first_name: [
          ...[
            !this.$v.user.first_name.required ? "este campo es requerido" : "",
          ].filter((x) => x != ""),
        ],
        last_name: [
          ...[
            !this.$v.user.last_name.required ? "este campo es requerido" : "",
          ].filter((x) => x != ""),
        ],
        username: [
          ...[
            !this.$v.user.username.required ? "este campo es requerido" : "",
          ].filter((x) => x != ""),
           ...[
            !this.$v.user.username.numeric ? "este campo solo permite números" : "",
          ].filter((x) => x != ""),
        ],
        email: [
          ...[
            !this.$v.user.email.required ? "este campo es requerido" : "",
          ].filter((x) => x != ""),
        ],
        password: [
          ...[
            !this.$v.user.password.required ? "este campo es requerido" : "",
          ].filter((x) => x != ""),
        ],
        role_id: [
          ...[
            !this.$v.user.role_id.required ? "este campo es requerido" : "",
          ].filter((x) => x != ""),
        ],
        type_user: [
          ...[
            !this.$v.user.type_user.required ? "este campo es requerido" : "",
          ].filter((x) => x != ""),
        ],
      };
    },
    ...mapState(["dataSource", "user", "permissions"]),
  },
};
</script>

<style scoped></style>
