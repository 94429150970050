<template>
  <v-container fluid>
    <template>
      <v-breadcrumbs>
        <template>
          <v-breadcrumbs-item>
            <v-icon color="primary">mdi-home</v-icon>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item>
            <v-icon>mdi-chevron-double-right</v-icon> LISTA USUARIOS
          </v-breadcrumbs-item>
          <v-breadcrumbs-item>
            <v-icon>mdi-chevron-double-right</v-icon>Usuario
            <strong class="ml-2"> #{{ usuario.name }} </strong>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </template>

    <create ref="eventForm" mode="edit"></create>
  </v-container>
</template>

<script>
import { showUser } from "../../services/configService";
import Swal from "sweetalert2";
import Create from "./users/Create.vue";
export default {
  name: "EditRoles",
  data() {
    return {
      usuario: {
        id: "",
        name: "",
        code: "",
      },
      eventId: "",
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    this.eventId = id;
    try {
      this.showLoader();
      const eventShowResponse = await showUser(id);
      const usuario = {
        first_name: eventShowResponse.data.first_name,
        last_name: eventShowResponse.data.last_name,
        username: eventShowResponse.data.username,
        email: eventShowResponse.data.email,
        territory_id: eventShowResponse.data.territory_id,
        role_id: eventShowResponse.data?.groups[0],
        id: eventShowResponse.data.id,
        type_user: eventShowResponse.data.type_user,
        department_id: eventShowResponse.data.department_id,
        municipality_id: eventShowResponse.data.municipality_id,
        zone_id: eventShowResponse.data.zone_id,
      };
      this.usuario = usuario;

      this.$nextTick(() => {
        this.$refs.eventForm.setEventData(usuario);
      });
      this.hideLoader();
    } catch (err) {
      this.hideLoader();
      console.error(err);
      Swal.fire(
        "Error",
        "ha ocurrido un error al procesar la solicitud",
        "error"
      );
    }
  },
  components: { Create },
};
</script>

<style scoped></style>
